/* global dnbvid */

export function enrich(apiKey, onEnrichment) {
  function appendScripts() {
    const sc1 = document.createElement('script')
    sc1.type = 'text/javascript'
    sc1.async = true
    sc1.defer = true
    sc1.src = `//${apiKey}.d41.co/sync/`

    const sc2 = document.createElement('script')
    sc2.type = 'text/javascript'
    sc2.src = '//cdn-0.d41.co/tags/dnb_coretag_v5.min.js'

    const body = document.getElementsByTagName('body')[0]
    body.appendChild(sc1)
    body.appendChild(sc2)
  }

  function getDnbData() {
    if (typeof (dnbvid) === 'undefined') {
      setTimeout(getDnbData, 50)
    } else {
      const startTime = new Date().getTime()
      dnbvid.getData(apiKey, 'json', 'T', {}, function(dnbData) {
        if (Object.prototype.hasOwnProperty.call(dnbData, 'duns')) {
          const requestDuration = new Date().getTime() - startTime
          onEnrichment({ duns: dnbData.duns }, requestDuration)
        }
      })
    }
  }

  appendScripts()
  getDnbData()
}
