import { updateEnrichment } from 'LiveBoard/api/enrichment'
import { enrich as sixSenseEnrich } from './sixSense'
import { enrich as dnbEnrich } from './dnb'
import { enrich as demandbaseEnrich } from './demandbase'

import get from 'lodash/get'

const PROVIDERS = {
  six_sense: {
    enrichmentMethod: sixSenseEnrich
  },
  dnb: {
    enrichmentMethod: dnbEnrich
  },
  demandbase: {
    enrichmentMethod: demandbaseEnrich
  }
}

const DataEnrichment = (config = {}) => {
  const type = config.type
  const enrichmentMethod = get(PROVIDERS[type], 'enrichmentMethod')
  if (!enrichmentMethod) {
    return
  }

  const apiKey = config.api_key

  const performEnrichment = () => {
    !!type && enrichmentMethod(apiKey, handleEnrichment)
  }

  const handleEnrichment = (enrichmentData, requestDuration = null) => {
    updateEnrichment(type, enrichmentData, requestDuration)
  }
  performEnrichment()
}

export default DataEnrichment
