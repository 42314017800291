export function enrich(apiKey, onEnrichment) {
  const cookieXhr = new XMLHttpRequest()
  const startTime = new Date().getTime()
  cookieXhr.onreadystatechange = function () {
    const cookie = cookieXhr.responseText
    if (cookieXhr.readyState === 4) {
      const firmographicXhr = new XMLHttpRequest()
      firmographicXhr.open('GET', 'https://epsilon.6sense.com/v3/company/details')
      firmographicXhr.setRequestHeader('Authorization', 'Token ' + apiKey)

      if (cookieXhr.status === 200 && cookie.match('6suuid=')) {
        const visitorCookie = cookie.split('=')[1]
        firmographicXhr.setRequestHeader('EpsilonCookie', visitorCookie)
      }

      firmographicXhr.onreadystatechange = function () {
        if (firmographicXhr.readyState === 4 && firmographicXhr.status === 200) {
          const requestDuration = new Date().getTime() - startTime
          onEnrichment(firmographicXhr.response, requestDuration)
        }
      }

      firmographicXhr.send()
    }
  }
  cookieXhr.open('GET', 'https://c.6sc.co/', true)
  cookieXhr.withCredentials = true
  cookieXhr.send()
}
